import catalogsLang from "@/langs/configuration/catalogs";

const configurationLang = {
  en: {
    configuration: "Configuration",
    transactionsVoucher: "Transaction voucher",
    start: "Start",
    end: "End",
    dateExpired: "Date expired",
    IDPlanificationExpenseRequest: "ID planification expense request",
    ...catalogsLang.en,
  },
  es: {
    configuration: "Configuración",
    transactionsVoucher: "Transacciones de voucher",
    start: "Inicio",
    end: "Fin",
    dateExpired: "Fecha vencimiento",
    IDPlanificationExpenseRequest: "ID compra",
    ...catalogsLang.es,
  },
};

export default configurationLang;
